@use "sass:color";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "../src/shared/styles/input.scss";
@import "../src/shared/styles/form.css";
@import "ck_commun/src/app/modUtils/style/flex.scss";
@import "../src/shared/styles/fonts.scss";
@import "../src/shared/styles/styleGlobal.scss";
@import "./cklibs/ck_commun/src/app/modUtils/style/AntPopover.scss";
@import "./cklibs/ck_commun/src/app/modUtils/style/breakpoints.scss";

// Définir les couleurs de base
$primary: #338EF5;
$secondary: #51BEFF;
$thirty: #063C79;
$accent: #36C31D;
$border-color: #ADD1FB;
$background: #FFFFFF;
$muted: #D1D9E4;
$muted-foreground: #a7a7a7;
$destructive: #CC0000;
$popover: #000000D9;

$primary-light: color.scale($primary, $lightness: 90%);
$card-foreground: color.scale($primary, $lightness: -50%);

:root {
    // thème
    --background-color: #{$background};
    --foreground-color: #{$primary};
    --muted-color: #{$muted};
    --muted-foreground-color: #{$muted-foreground};
    --popover-color: #{$popover};
    --popover-foreground-color: #{$background};
    --card-color:#{$background};
    --card-foreground-color: #{$card-foreground};
    --border-color: #{$border-color};
    --input-color: #{$primary};
    --primary-color: #{$primary};
    --primary-light-color: #{$primary-light};
    --primary-foreground-color: #{$background};
    --secondary-color: #{$secondary};
    --secondary-foreground-color: #{$background};
    --thirty-color: #{$thirty};
    --thirty-foreground-color: #{$background};
    --accent-color: #{$accent};
    --accent-foreground-color: #{$background};
    --destructive-color: #{$destructive};
    --destructive-foreground-color:  #{$background};
    // thème
}

#root {
    height: 100%;
    background-color: var(--background-color);
    color: var(--foreground-color);
}

.filters>* {
    margin-bottom: 15px;
}

/************************* scrollbar *******************************/
/* Styles pour Firefox */
* {
    scrollbar-color: var(--muted-foreground-color) var(--background-color);  /* Couleur du thumb et track */
    scrollbar-width: thin;  /* Épaisseur de la scrollbar */
}

/* Webkit (Chrome, Safari) */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

/* Styles pour la barre de défilement elle-même (track) dans Firefox */
::-webkit-scrollbar-track {
    background: var(--background-color);  /* Couleur de l'arrière-plan */
}

/* Styles pour la poignée de la barre de défilement (thumb) dans Firefox */
::-webkit-scrollbar-thumb {
    background: var(--muted-foreground-color);
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #c5c4c4;
}
