@import "shared/styles/styleGlobal.scss";

// .menu-item {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   position: relative;
// }

.menu-expand-icon {
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-left: auto;
  margin-right: 10px;
}

.menu-expand-icon.expanded {
  transform: rotate(180deg);
}

ul.menu {
  list-style: none;
  margin-block-start: 0;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menu li {
  cursor: pointer;
  padding: 8px 0;
  transition: background-color 0.3s ease;
  width: 100%;
  display: flex;
  align-items: center;
}

.menu .menu-item-icon {
margin-right: 10px;
margin-left: 24px;
}

.menu .menu-item-label {
  size: 1em;
  text-align: left;
  line-height: 1.5em;
  color: #fff;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.menu li:hover:not(:has(.submenu:hover)) {
  background-color: $menu-hover-bg-color;
  filter: brightness(85%); 
}

.menu-active {
  border-left: solid 3px $menu-active-left-border-color;
  background-color: $menu-hover-bg-color;
  filter: brightness(85%); 
}

ul.submenu {
  list-style: none;
  margin-block-start: 0;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.submenu li {
  padding-left: 52px;
  cursor: pointer;
}

.menu-item.tooltip {
  position: relative;
  display: inline-block;
  z-index: 10;
}

.menu-item.tooltip .tooltiptext {
  visibility: hidden;
  background-color: $menu-bg-color;
  border-radius: 5px;
  position: absolute;
  z-index: 99999;
}

.menu-item.tooltip:hover .tooltiptext {
  visibility: visible;
}

.menu-item .tooltiptext .submenu li,
.menu-item .tooltiptext .menu-item-label {
  padding-left: 1em;
  padding-right: 1em;
  cursor: pointer;
  width: 100%;
}
