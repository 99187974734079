@import "shared/styles/styleGlobal.scss";

.btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: $button-border-width $button-border-type transparent;
  cursor: $button-cursor;
  transition: $button-transition;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-weight: $button-font-weight;
  font-size: $button-font-size;
  border-radius: $button-border-radius;
  color: $button-color;
  // border-color: #d9d9d9;
  // background: #fff;
}

.btn-image {
  background: none;
  border: none;
  padding: 0;
  margin: 0 0 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #7d7d7d;
}

.btn-image span {
  margin-top: 3px;
  font-size: 10px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
}

.btn>.icon {
  line-height: 1;
}

.btn,
.btn:active,
.btn:focus {
  outline: 0;
}

.btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}

.btn[disabled] {
  cursor: not-allowed;
}

.btn[disabled]>* {
  pointer-events: none;
}

.btn-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px;
}

.btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 2px;
}

.btn>a:only-child {
  color: currentcolor;
}

.btn>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}

.btn:hover,
.btn:focus {
  // color: #40a9ff;
  // border-color: #40a9ff;
  // background: #fff;
}

.btn:hover>a:only-child,
.btn:focus>a:only-child {
  color: currentcolor;
}

.btn:hover>a:only-child::after,
.btn:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}

.btn:active>a:only-child {
  color: currentcolor;
}

.btn:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}

.btn[disabled],
.btn[disabled]:hover,
.btn[disabled]:focus,
.btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.btn[disabled]>a:only-child,
.btn[disabled]:hover>a:only-child,
.btn[disabled]:focus>a:only-child,
.btn[disabled]:active>a:only-child {
  color: currentcolor;
}

.btn[disabled]>a:only-child::after,
.btn[disabled]:hover>a:only-child::after,
.btn[disabled]:focus>a:only-child::after,
.btn[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}

// .btn:hover,
// .btn:focus,

// .btn:active {
//   background: #fff;
// }

.btn>span {
  display: inline-block;
}

.btn-primary,
.btn-primary:focus {
  color: var(--primary-foreground-color);
  border: 1px solid var(--primary-foreground-color);
  background: var(--primary-color);
}

.btn-primary:hover {
  filter: brightness(0.9); // Assombrit la couleur de 10%
}
.btn-default:hover {
  color: var(--primary-foreground-color);
  border: 1px solid var(--primary-foreground-color);
  background: var(--primary-color);
}

.btn-default,
.btn-default:focus {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background: var(--primary-foreground-color);
}

.btn-text,
.btn-text:focus {
  color: $button-text-color;
  border-color: $button-text-border-color;
  background: $button-text-bg-color;
  box-shadow: none;
}

.btn-text:hover {
  color: $button-text-hover-color;
  border-color: $button-text-hover-border-color;
  background: $button-text-hover-bg-color;
  text-decoration: underline;
  filter: brightness(0.9); // Assombrit la couleur de 10%
}

// .btn::before {
//   position: absolute;
//   top: -1px;
//   right: -1px;
//   bottom: -1px;
//   left: -1px;
//   z-index: 1;
//   display: none;
//   background: #fff;
//   border-radius: inherit;
//   opacity: 0.35;
//   transition: opacity 0.2s;
//   content: "";
//   pointer-events: none;
// }

.btn .icon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.btn:focus>span,
.btn:active>span {
  position: relative;
}

.btn>.icon+span,
.btn>span+.icon {
  margin-left: 8px;
}

.btn.btn-block {
  width: 100%;
}

.btn.btn-round {
  border-radius: 50%;
}

.btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: "\a0";
}

a.btn {
  padding-top: 0.01px !important;
  line-height: 30px;
}

a.btn-disabled {
  cursor: not-allowed;
}

a.btn-disabled>* {
  pointer-events: none;
}

a.btn-disabled,
a.btn-disabled:hover,
a.btn-disabled:focus,
a.btn-disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}

a.btn-disabled>a:only-child,
a.btn-disabled:hover>a:only-child,
a.btn-disabled:focus>a:only-child,
a.btn-disabled:active>a:only-child {
  color: currentcolor;
}

a.btn-disabled>a:only-child::after,
a.btn-disabled:hover>a:only-child::after,
a.btn-disabled:focus>a:only-child::after,
a.btn-disabled:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}

a.btn-lg {
  line-height: 38px;
}

a.btn-sm {
  line-height: 22px;
}