@import 'shared/styles/styleGlobal.scss';

.pagination-select-label {
  text-align: left;
  font-weight: $select-label-font-weight;
  font-size: 11px;
  color: var(--primary-color);
}

.pagination-container {
  display: flex;
  margin-bottom: 10px;
}

.pagination .page-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .page-link {
  padding: 4px 10px;
  border-radius: 5px;
  border: 1px solid var(--input-color);
  margin-right: 5px;
}

.prec-btn,
.next-btn {
  margin-right: 2%;

  .btn {
    padding: 4px 10px;
  }
}

.pagination .page-link:hover {
  // background-color: #e0e0e0;
  background-color: var(--background-color);
}

.pagination .page-link:focus {
  box-shadow: none;
}

.pagination .page-link:active {
  // background-color: #c0c0c0;
  background-color: var(--background-color);
}

.pagination .page-item.active .page-link {
  // background-color: #007bff;
  background-color: var(--primary-color);
  color: var(--primary-foreground-color);
  border-color: var(--input-color);
}

.pagination .page-item.disabled .page-link {
  color: var(--muted-foreground-color);
  background-color: var(--muted-color);
  border-color: var(--input-color);
}

.pagination .page-item.disabled .page-link:hover {
  cursor: not-allowed;
  background-color: var(--background-color);
}

/*pour chrome*/
.pagination .form-control::-webkit-outer-spin-button,
.pagination .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*pour firefox*/
.pagination .form-control[type="number"] {
  -moz-appearance: textfield;
}

.pagination .form-control {
  width: 30px;
  height: 25px;
  border: 1px solid var(--input-color);
  padding: 5px;
  background-color: var(--background-color);
  text-align: center;
}

.pagination .pagination-select {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.pagination .ml-3 {
  margin-left: 20px;
}


.pagination .form-select {
  border: 1px solid var(--input-color);
  width: 70px;
  height: 25px;
  border-radius: 5px;
  color: var(--primary-color);
}

.pagination .form-select::after {
  content: 'x';
  color: var(--secondary-color);
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.pagination .btn-primary {
  margin-left: 5px;
}

.pagination .pagination-ellipsis {
  text-align: center;
  font-weight: bold;
  padding-right: 0.5em;
}