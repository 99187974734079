.ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    font-size: 12px;
    line-height: 1.5715;
    background-image: none;
    border-radius: 5px;
    transition: all 0.3s;
}

.ant-input,
.ant-input-affix-wrapper,
.ant-select-item,
.ant-select-item-option,
.ant-select-item-option-selected,
.ant-select-selector,
.ant-picker,
.ant-picker-panel-container
{
    background-color: var(--background-color) !important;
}

.ant-input,
.ant-select-open
.ant-select-item,
.ant-select-item-option,
.ant-select-item-option-selected,
.ant-select-selection-item,
.ant-select-selector
{
    color: var(--foreground-color) !important;
}

.ant-btn,
.ant-select-selector,
.ant-select-dropdown,
.ant-picker,
.ant-picker-panel-container,
.ant-input,
.ant-input-affix-wrapper
{
    border: 1px solid var(--input-color) !important;
}

.anticon,
.ant-select-selection-search-input,
.ant-picker-input,
.ant-picker-panel-container,
.ant-picker-cell-in-view,
.ant-picker-cell-in-view:hover .ant-picker-cell-inner,
.ant-picker-header-prev-btn,
.ant-picker-header-super-prev-btn
.ant-picker-header-next-btn,
.ant-picker-header-super-next-btn
.ant-picker-header-view,
.ant-picker-month-btn:hover,
.ant-picker-year-btn:hover,
.ant-picker-now-btn,
.ant-picker-content th,
.ant-picker-time-panel-cell-inner,
.ant-picker-time-panel-cell:hover
{
    color: var(--input-color) !important;
}

.ant-picker-cell,
.ant-input[disabled],
.ant-input::placeholder,
.ant-picker-input input::placeholder,
.ant-select-selection-search-input::placeholder
{
    color: var(--muted-foreground-color) !important;
}

.select-group-container {
    .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow.ant-select-show-search.select-customize-input {
        width: 100%;
        .ant-select-selector {
            height: 40px;
            border-radius: 5px;
            .ant-select-selection-search input.ant-select-selection-search-input {
                color: var(--primary-color);
            }
        }
    }
    .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow.ant-select-show-search.ant-select-open.select-customize-input {
        .ant-select-selection-item {
            color: #bfbfbf;
        }
    }
    .ant-select-arrow{
        top:60% !important;
    }
}

.ant-select-dropdown {
    border-top: none !important;
    border-radius: 0 0 5px 5px !important;
    margin-top: -1px !important;

    .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
        font-weight: bold;
    }
}

.ant-picker-cell-in-view.ant-picker-cell-selected {
    .ant-picker-cell-inner {
        background-color: var(--primary-color) !important;
        color: var(--primary-foreground-color) !important;
    }
}

.ant-picker-cell-inner::before {
    border: none !important;
}

.ant-picker-cell-inner {
    border: 1px solid transparent;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
    border: 1px solid var(--primary-color) !important;
}

.ant-picker-cell-in-view:hover .ant-picker-cell-inner {
    background-color: var(--primary-light-color) !important;
    border: 1px solid var(--primary-light-color) !important;
}

/* Cibler les boutons OK ou Cancel dans le calendrier */
.ant-picker-footer-btns .ant-picker-footer-btn {
    background-color: var(--accent-color) !important;
    color: var(--accent-foreground-color) !important; 
    border: 1px solid var(--accent-color) !important;
}

.ant-btn {
    background-color: var(--input-color) !important;
}

.ant-picker-time-panel-cell:hover .ant-picker-time-panel-cell-inner {
    background-color: var(--primary-light-color) !important;
    border: 1px solid var(--primary-light-color) !important;
}

.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background-color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-foreground-color) !important;
}

.ant-picker-dropdown .ant-picker-time-panel-column {
    scrollbar-width: thin;
    scrollbar-color: var(--muted-foreground-color) transparent;
}

.ant-input-affix-wrapper {
    input {
        border: none !important;
    }
}

.ant-select-multiple .ant-select-selection-placeholder {
    transform: translateY(-30%) translateX(-2%) !important;
}

.ant-input[disabled] {
    background-color: var(--muted-color) !important;
    border-color: var(--muted-foreground-color) !important;
    box-shadow: none !important;
    cursor: not-allowed !important;
    opacity: 1 !important;
}

.search-site {
    border: solid 1px var(--input-color);
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    padding: 0 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
        all: unset;
        width: 170px;

        &::placeholder {
            color: var(--muted-foreground-color);
        }

        &::-webkit-input-placeholder {
            color: var(--muted-foreground-color);
        }

        &::-moz-placeholder {
            color: var(--muted-foreground-color);
        }

        &:-ms-input-placeholder {
            color: var(--muted-foreground-color);
        }

        &:-moz-placeholder {
            color: var(--muted-foreground-color);
        }
    }
}

.select-site-container {
    color: var(--muted-foreground-color);

    .ant-select-selection-placeholder {
        color: var(--muted-foreground-color);
    }

    .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow.ant-select-show-search.select-customize-input,
    .ant-select.ant-select-outlined.ant-select-multiple.ant-select-show-arrow.ant-select-show-search.select-customize-input {
        .ant-select-selector {
            height: 40px;
            border: 1px solid var(--input-color);
            border-radius: 5px;
            background-color: var(--card-color);
            color: var(--muted-foreground-color);

            .ant-select-selection-search input.ant-select-selection-search-input {
                color: var(--primary-color);
            }
        }

        .ant-select-selection-item {
            color: var(--muted-foreground-color);
        }
    }
    .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow.ant-select-show-search.ant-select-open.select-customize-input,
    .ant-select.ant-select-outlined.ant-select-multiple.ant-select-show-arrow.ant-select-show-search.ant-select-open.select-customize-input {
        .ant-select-selection-item {
            color: #bfbfbf;
        }
    }
}
.ant-select-dropdown,
.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
    border: 1px solid var(--input-color) !important;
    border-top: none !important;
    border-radius: 0 0 5px 5px !important;
    margin-top: -1px !important;
    background-color: var(--card-color) !important;
}


.select-customize-input .ant-select-selection-placeholder {
    color: var(--muted-foreground-color);
}

.ant-form-item-explain-error {
    color: var(--destructive-color) !important;
}

.ant-select-selection-search-input {
    color: var(--primary-color) !important;
}

.input {
    .input-wrapper {
        border-radius: 5px;
        text-align: left;
        vertical-align: middle;
        padding: 5px;
        overflow: hidden;
        border: solid 1px var(--input-color);
        background-color: var(--background-color);
        margin-bottom: 10px;
    }
    .input-wrapper:focus-within {
        box-shadow: 0 0 0 2px var(--input-color); /* Apply box-shadow when input is focused */
      }
    input::placeholder {
        color: var(--muted-foreground-color);
    }

    input {
        all: unset;
        width: 100%;
        margin-right: 10px;
    }

    .input-label {
        color: var(--muted-foreground-color);
        font-size: 14px;
        font-weight: bold;
        text-transform: capitalize;
    }
}

#form .input .input-wrapper {
    border-radius: 5px;
    color: var(--primary-color);
    text-align: left;
    vertical-align: middle;
    padding: 5px;
    overflow: hidden;
    border: solid 1px var(--input-color);
    background-color: var(--background-color);
    margin-bottom: 10px;
}

#form .input-label {
    color: var(--primary-color);
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
}

.siteUpdateInfos {
    .input {
        .input-wrapper {
            border: none !important;
            background-color: unset !important;
        }
    }
}

.credit-support-form {
    .input .input-wrapper {
        border-radius: 5px;
        color: var(--primary-color);
        text-align: left;
        vertical-align: middle;
        padding: 5px;
        overflow: hidden;
        margin-bottom: 0;

    }

    .ant-form {
        .ant-form-item {
            label {
                color: var(--primary-color) !important;
                font-size: 14px !important;
                font-weight: bold !important;
                text-transform: capitalize !important;
            }
        }
    }

    .ant-picker {
        height: 40px;
        border: 1px solid var(--input-color);
        border-radius: 5px;
        background-color: var(--background-color);
        color: var(--primary-color);
    }
}

.credit-support-form{
    .ant-form-item {
        label {
            color: var(--primary-color);
            font-size: 14px !important;
            font-weight: bold !important;
            text-transform: capitalize !important;
        }
    }
}

.entity-infos-modal-form {
    .ant-form-item {
        label {
            color: var(--primary-color);
            font-size: 14px !important;
            font-weight: bold !important;
            text-transform: capitalize !important;
        }
    }
}

.entity-infos-modal-form {
    .input .input-wrapper {
        border-radius: 5px;
        color: var(--primary-color);
        text-align: left;
        vertical-align: middle;
        padding: 5px;
        overflow: hidden;
        border: solid 1px var(--input-color);
        background-color: var(--background-color);
        margin-bottom: 0;

    }

    .ant-form {
        .ant-form-item {
            label {
                font-size: 14px !important;
                font-weight: bold !important;
                text-transform: capitalize !important;
            }
        }
    }

    .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow.select-customize-input {
        .ant-select-selector {
            height: 40px;
            border: 1px solid var(--input-color);
            border-radius: 5px;
            background-color: var(--background-color) !important;

            .ant-select-selection-search input.ant-select-selection-search-input {
                color: var(--primary-color)
            }
        }

        .ant-select-selection-item {
            color: var(--primary-color)
        }

        .ant-select-arrow {
            top: 60% !important;
            color: var(--primary-color)
        }
    }

    .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow.ant-select-open.select-customize-input {
        .ant-select-selection-item {
            color: var(--muted-foreground-color);
        }
    }

    .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow.select-customize-input.ant-select-disabled {
        .ant-select-selector {
            height: 40px;
            border-color: var(--muted-foreground-color);
            border-radius: 5px;

            .ant-select-selection-search input.ant-select-selection-search-input {
                color: var(--muted-foreground-color);
            }

            .ant-select-selection-item {
                color: var(--muted-foreground-color);
            }
        }

        .ant-select-disabled.ant-select-arrow {
            top: 60% !important;
            color: var(--muted-foreground-color);
        }
    }

    .ant-picker {
        height: 40px;
        border: 1px solid var(--input-color);
        border-radius: 5px;
        background-color: var(--background-color);
        color: var(--primary-color);
    }
}
