/* colors */
$white: #FFFFFF;//color blanc pur
$black: #000000;//color noir pur
$very-light-white-1: #FCFCFC;//color blanc très clair
$very-light-white-2: #EFF7FC;//color blanc très clair

$very-light-blue-1:#C2DDFC;//color bleu très clair
$light-blue-2:#51BEFF;//color bleu clair
$medium-blue-1: #57A7F2;//color bleu moyen
$dark-blue-1: #00CFF933;//color bleu foncé

$light-gray-1: #D0D0D0;//color gris clair
$medium-gray-1: #999999;//color gris moyen
$very-dark-gray-1: #333333;//color gris très foncé

/* Border, Border radius and Box shadow */
$box-shadow-color: $medium-gray-1;
$border-radius-base: 10px;

/* Menu */
$menu-bg-color: var(--primary-color);
// $menu-hover-bg-color: darken(#338EF5, 7%);
$menu-hover-bg-color: var(--primary-color);
$menu-active-left-border-color: var(--destructive-color);

/* Sider */
$sider-bg-color: var(--primary-color);


/* Modal */
$modal-overlay-bg: $black;
$modal-overlay-opacity: 75%;
$modal-bg-color: $light-gray-1;
$modal-box-shadow: 0px 3px 6px $box-shadow-color;
$modal-border-radius: $border-radius-base;
$modal-max-width: 95%;
$modal-max-height: 95%;
$modal-padding: 20px 35px;
$modal-zIndex: 8888;
$modal-title-color: var(--secondary-color);
$modal-title-font-weight: bold;
$modal-title-font-size: 24px;
$modal-title-line-height: 32px;
$modal-title-margin-bottom: 25px;
$modal-title-text-transform: uppercase;
$modal-title-text-align: center;
$modal-close-color: var(--secondary-color);
$modal-close-size: 32px + 8;

/* Card */
// $card-box-shadow: 0px 3px 16px $box-shadow-color;
$card-box-shadow: 0px 3px 16px #bdbdbd;
//
$card-border-radius: $border-radius-base ;
$card-bg-color: var(--card-color);
$card-margin: 5px 10px;
$card-text-align: center;
$card-border: 1px solid $medium-blue-1;
$card-header-bg-color: var(--card-color);
$card-header-border-radius: $card-border-radius $card-border-radius 0 0;
$card-header-padding: 5px; 
$card-title-font-size: 12px;
$card-title-line-height: $card-title-font-size;
$card-title-color: $light-gray-1;
$card-body-padding: 6px 10px 6px 10px;

/* Tabs */
$tabs-header-bg-color: var(--primary-color);
$tabs-header-element-margin-right:5px;
$tabs-header-element-padding: 5px;
$tabs-header-element-border-radius:  5px 5px 0 0;
$tabs-header-height: 40px;
$tabs-header-label-color: $light-gray-1;
$tabs-header-label-text-transform: uppercase;
$tabs-header-label-font-size: 12px;
$tabs-header-label-font-weight: 500;
$tabs-header-label-line-height: $tabs-header-label-font-size;
$tabs-content-padding: 10px 0;
$tabs-content-bg-color: $light-gray-1;
$tabs-content-border: 1px solid var(--secondary-color);
$tabs-content-border-radius: 0 0 5px 5px;

/* Select */
$select-color: var(--primary-color);
$select-label-font-size: 14px;
$select-label-font-weight: bold;
$select-label-margin: 0 0 5px 10px;
$select-border: 1px solid var(--primary-color);
$select-background-color: $light-gray-1;
$select-margin: 0 10px 0 10px ;
$select-height: 40px;
$select-border-radius: 5px;
$select-disabled-bg-color: $very-light-blue-1;

/* Button */
$button-border-radius: 3px;
$button-border-width: 1px;
$button-border-type: solid;
$button-font-weight: "bold";
$button-font-size: 14px;
$button-cursor: pointer;
$button-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
/*******/
$button-border-color: var(--primary-color);
$button-color: $light-gray-1;
$button-bg-color: var(--primary-color);
/****hover*/
/* button primary */
$button-primary-border-color: var(--primary-color);
$button-primary-color: $light-gray-1;
$button-primary-bg-color: var(--primary-color);
/* primary hover */
$button-primary-hover-border-color: var(--primary-color);
$button-primary-hover-color: $light-gray-1;
$button-primary-hover-bg-color: var(--primary-color);
/* button default */
$button-default-border-color: var(--primary-color);
$button-default-color: var(--primary-color);
$button-default-bg-color: $light-gray-1;
/* default hover */
$button-default-hover-border-color: var(--primary-color);
$button-default-hover-color: $light-gray-1;
$button-default-hover-bg-color: var(--primary-color);
/* button text */
$button-text-border-color: transparent;
$button-text-color: var(--primary-color);
$button-text-bg-color: transparent;
/* text hover */
$button-text-hover-border-color: transparent;
$button-text-hover-color: var(--primary-color);
$button-text-hover-bg-color: transparent;

/*table*/
$table-header-bg-color: $light-blue-2;
$table-header-border: solid 1px $very-light-white-1;
$table-cell-align: center;
$table-header-text-transform: uppercase;
$table-header-font-weight: bold;
$table-cell-font-size: 12px - 1;

$table-header-color: $light-gray-1;
$table-row-height: 32px;
$table-cell-font-weight: 400;
$table-cell-border: solid 1px $dark-blue-1;
$table-stripped-even-bg-color: $very-light-white-2;
$table-row-bg-color: $white;
$table-row-bg-color-hover: $very-light-white-2;