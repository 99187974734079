.ant-popover {
    background-color: var(--card-color);
    color: var(--card-foreground-color);
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .ant-popover-inner {
        background-color: var(--card-color);
        color: var(--card-foreground-color);
    }

    .ant-popover-arrow {
        &::before {
            background-color: var(--card-color); // Pour s'assurer que la flèche correspond au popover
        }
    }
}

.ant-popover.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
    padding-top: 0 !important;
}