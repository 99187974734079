.config-tree-node-container {
    width: 100%;
}

.config-tree-node {
    font-weight: bold;
    width: 100%;
    border-style: solid;
    border-width: 1px;

    &.active {
        color: var(--primary-color);
        border-color: var(--primary-color);
    }

    &.next {
        color: #7BA1CB;
        border-color: #7BA1CB;
    }

    &.expired {
        color: var(--muted-foreground-color);
        border-color: var(--muted-foreground-color);
    }

    &.selected {
        color: #ffff;
        box-shadow: 0px 0px 10px #00000029;

        &.active {
            background-color: var(--primary-color);
        }

        &.next {
            background-color: #7BA1CB;
        }

        &.expired {
            background-color: var(--muted-foreground-color);
        }
    }

    .node-switcher-icon {
        transform: rotate(270deg);
    }
}

.rc-tree .rc-tree-treenode {
    display: flex;
}

.rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper {
    height: fit-content;
    width: 100%;
}

.rc-tree-title {
    display: flex;
}