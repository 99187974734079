.react-calendar {
    background-color: var(--card-color) !important;
    border: none;
}

.react-calendar__tile {
    background-color: var(--background-color) !important;
}

// Ne modifie que les jours du mois en cours
.react-calendar__month-view__days__day:not(.react-calendar__month-view__days__day--neighboringMonth) {
    color: var(--card-foreground-color) !important;
}

// Laisse les jours en dehors du mois tels quels
.react-calendar__month-view__days__day--neighboringMonth {
    color: var(--muted-foreground-color);
}

// Date sélectionnée
.react-calendar__tile--active {
    background-color: var(--primary-color) !important;
}

.react-calendar__tile--active.react-calendar__month-view__days__day {
    color: var(--primary-foreground-color) !important;
}

// Hover
.react-calendar__tile:hover {
    background-color: var(--secondary-color) !important;
}
