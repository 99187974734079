@import "shared/styles/styleGlobal.scss";

.table {
  border-collapse: collapse;
  width: 100%;
}

.table th,
.table td {
  text-align: $table-cell-align;
  height: auto;
  min-height: 32px;
  font-size: $table-cell-font-size;
  font-weight: $table-header-font-weight;
  padding: 0 5px;
}

.table th,
.table td {
  line-height: 32px;
}

.table th {
  height: 40px;
  background-color: var(--secondary-color);
  border: 1px solid var(--border-color);
  text-transform: uppercase;
  font-weight: $table-header-font-weight;
  color: var(--primary-foreground-color);
}

.table tr {
  background-color: var(--background-color);
}

.table tr td {
  border: 1px solid var(--border-color);
  font-weight: $table-cell-font-weight;
}

.table.table-stripped tr:nth-child(2n) {
  background-color: var(--primary-light-color);
}

.table tr:hover,
.table.table-stripped tr:nth-child(2n):hover {
  background-color: var(--primary-light-color);
}

.table td:first-child {
  white-space: nowrap;
}

.table input[type="checkbox"] {
  width: 30px;
}

.table-scrollable thead th {
  position: sticky;
  top: 0;
}

.table-scrollable {
   overflow-y: auto;
   overflow-x: auto;
}

.table-overflow {
  overflow-y: auto;
  overflow-x: clip;
}

/*highlight column for amounts in table */
.table-column-accent {
  background-color: var(--secondary-color);
  border: 1px solid var(--border-color);
  color: var(--secondary-foreground-color) !important;
}

/* classnames for highlight row in a table*/
tr.table-row-clicked ,tr.table-row-clicked:hover, .table.table-stripped tr.table-row-clicked, .table.table-stripped tr.table-row-clicked:hover, tr.table-row-clicked .table-column-accent{ 
  background-color: var(--accent-color);

}

tr.table-row-highlight ,tr.table-row-highlight:hover, .table.table-stripped tr.table-row-highlight, .table.table-stripped tr.table-row-highlight:hover, tr.table-row-highlight .table-column-accent{ 
  background-color: var(--destructive-color);

}

tr.table-row-test ,tr.table-row-test:hover, .table.table-stripped tr.table-row-test, .table.table-stripped tr.table-row-test:hover, tr.table-row-test .table-column-accent{ 
  background-color: #f2d73c;
}